import React, { useContext, useRef } from 'react'

import ReactAudioPlayer from 'react-audio-player'

import { EpisodesContext } from '../../contexts/EpisodesContext'

export const AudioPlayer = ({ url, id }) => {
  const { state, dispatch } = useContext(EpisodesContext)

  const player = useRef(null)

  const setEpisode = () => {
    if (state.player && state.playerStatus === 'PLAY') {
      state.player.audioEl.current.pause()
    }

    // set player in context
    dispatch({ type: 'setPlayer', payload: player.current })

    dispatch({
      type: 'setCurrent',
      payload: id,
    })

    dispatch({ type: 'setPlayerStatus', payload: 'PLAY' })
  }

  const setPause = () => {
    dispatch({ type: 'setPlayerStatus', payload: 'PAUSE' })

    dispatch({
      type: 'setCurrent',
      payload: null,
    })

    dispatch({ type: 'setPlayer', payload: null })
  }

  return (
    <>
      <div className="py-2">
        <ReactAudioPlayer
          id={id}
          src={url}
          controls
          onPlay={() => setEpisode()}
          onPause={() => setPause()}
          ref={player}
          preload="none"
        />
      </div>
    </>
  )
}
