import React, { useEffect, useState } from 'react'

import Img from 'gatsby-image'
import { getFluidImageObject } from 'gatsby-transformer-cloudinary/api'

function millisToMinutes(millis) {
  const minutes = Math.floor(millis / 60)
  return minutes
}

interface IImageCoverProps {
  duration: number
  episodeNumber: number
  alt: string
  image: string
}

export const ImageCover: React.FC<IImageCoverProps> = ({ image, alt, duration, episodeNumber }) => {
  const [fluid, setFluid] = useState(undefined)
  const durationFormated = `${millisToMinutes(duration)} min`

  const pathCloudinary = `gatsby-cloudinary/episodes/${image}`

  useEffect(() => {
    getFluidImageObject({
      public_id: pathCloudinary,
      cloudName: 'worldwebstories',
      originalHeight: 380,
      originalWidth: 400,
      breakpoints: [200, 400, 600, 800],
    }).then((result) => setFluid(result))
  }, [pathCloudinary])

  return (
    <div className="relative">
      {fluid ? <Img fluid={fluid} alt={alt} className="img-cover rounded-xl" /> : <>Loading...</>}
      <div className="absolute bottom-5 left-5 ">
        <div className="text-white uppercase text-lg font-bold mb-5 shadow">{durationFormated}</div>
        <span className="text-white uppercase text-6xl font-bold shadow">EP{episodeNumber}</span>
      </div>
    </div>
  )
}
