import React from 'react'

import classnames from 'classnames'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { v4 as uuid } from 'uuid'

import { LinkCustom } from '../LinkCustom'

const Continents = ({ data, active, opacity = false }) => {
  const { nodes } = data.allContinentsYaml

  return (
    <div className="bg-white py-10">
      <ul className="mx-auto grid grid-cols-3 gap-x-4 gap-y-8 px-3 sm:grid-cols-5 md:grid-cols-7 md:gap-x-6 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12 xl:grid-cols-7 ">
        {nodes.map((continent) => (
          <li key={uuid()}>
            <div className="space-y-4 text-center">
              {continent.img ? (
                <LinkCustom to={continent.link} className="group" title={continent.name}>
                  <GatsbyImage
                    image={getImage(continent.img)}
                    loading="eager"
                    className={classnames(
                      'mx-auto rounded-full h-24 w-24 md:h-20 md:w-20 transform hover:-translate-y-1 group-focus:opacity-100 lg:w-32 lg:h-32 hover:scale-110 hover:ease-in-out',
                      opacity &&
                        (active === continent.link ? 'border-8 border-primary' : 'opacity-50')
                    )}
                    alt=""
                  />
                </LinkCustom>
              ) : (
                <>Loading...</>
              )}
              <div className="space-y-2">
                <h3
                  className={classnames(
                    'uppercase p-1 mt-3 text-center text-xl',
                    active === continent.link && 'font-bold'
                  )}
                >
                  {continent.name}
                </h3>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export const ContinentsList = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allContinentsYaml {
            nodes {
              name
              link
              img {
                childImageSharp {
                  gatsbyImageData(width: 150, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                }
              }
            }
          }
        }
      `}
      render={(data) => <Continents data={data} {...props} />}
    />
  )
}
