import React, { useContext } from 'react'

import { format } from 'date-fns'
import { isMobile } from 'react-device-detect'

import { EpisodesContext } from '../../contexts/EpisodesContext'
import { IPodcastListNode } from '../../hooks'
import { AudioPlayer } from '../AudioPlayer'
import { Bars } from '../Bars'
import { ImageCover } from '../ImageCover'
import { LinkCustom } from '../LinkCustom'

export const Episode: React.FC<IPodcastListNode> = ({ node }) => {
  const { state } = useContext(EpisodesContext)
  const { frontmatter, fields, id } = node
  const {
    url,
    title,
    subtitle,
    duration,
    country,
    imageCover,
    imageCoverAlt,
    episodeNumber,
    publicationDate,
  } = frontmatter

  const { slug } = fields

  const dateFormated = format(new Date(publicationDate), 'PP')
  const dateTime = format(new Date(publicationDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")

  return (
    <section className="bg-white border-b border-gray-200 rounded-xl mb-8 relative">
      <div className="grid grid-cols-1 md:grid-cols-3">
        <div className="flex-shrink-0 sm:mr-4 relative">
          <LinkCustom to={`${slug}/`} title={title}>
            <ImageCover
              image={imageCover}
              duration={duration}
              episodeNumber={episodeNumber}
              alt={imageCoverAlt}
            />
          </LinkCustom>
        </div>

        <div className="py-7 px-3 col-span-2">
          <div className="mb-7">
            <span
              itemProp="name"
              className="uppercase font-bold text-xl tracking-widest text-primary"
            >
              {country}
            </span>

            <span className="px-2 text-3xl" aria-hidden="true">
              •
            </span>

            <time dateTime={dateTime} className="uppercase text-xl font-light">
              {dateFormated}
            </time>
          </div>

          <h3 className="text-3xl leading-8 text-gray-900 max-w-xl font-semibold mb-6 hover:underline md:truncate">
            <LinkCustom to={`${slug}/`} title={title}>
              {title}
            </LinkCustom>
          </h3>

          <p className="mb-7 font-serif font-sm">
            {!isMobile && subtitle.length >= 160 ? `${subtitle.slice(0, 160)}...` : subtitle}
          </p>

          <div className="mt-auto">
            <AudioPlayer url={url} id={id} />
          </div>

          <div className="absolute bottom-0">
            <Bars isPlaying={state.player?.props.id === id ? true : false} />
          </div>
        </div>
      </div>
    </section>
  )
}
