import React from 'react'

import { v4 as uuid } from 'uuid'

import { Episode } from '../Episode'

export const EpisodeList: React.FC = ({ podcasts }) => {
  return (
    <>
      <div className="py-5">
        {podcasts.edges.map(({ node }) => {
          return <Episode key={uuid()} node={node} />
        })}
      </div>
    </>
  )
}
